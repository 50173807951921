import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    layout,
    introBox,
    backgroundTop,
    recipesBox,
    recipeList,
    secret,
    secretHeader,
    secretContent,
} from './mulled-page.module.scss';
import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IProductPreview } from '../models/product-preview.model';
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import PageIntro from '../components/organisms/page-intro';
import ProductList from '../components/organisms/product-list';
import BackgroundRepeat from '../layouts/background-repeat';

interface IMulledPageProps {
    readonly data: {
        page: IPage | null;
        allStrapiOldRecipe: IQueryAllResult<IProductPreview>; //TODO: Zmienic nazwy w strapi
    };
}

const MulledPage: React.FC<IMulledPageProps> = ({ data }) => {
    const { allStrapiOldRecipe, page } = data;
    const { t } = useI18next();

    const recipes = getNodes(allStrapiOldRecipe);
    const { introTitle, introContent } = page || {};

    return (
        <MainLayout className={layout} page={page}>
            <div className={introBox}>
                <PageIntro title={introTitle} description={introContent} />
            </div>
            <StaticImage
                className={backgroundTop}
                src="../assets/images/mulled-bg-top-edge.png"
                objectFit="contain"
                alt=""
                quality={75}
            />
            <BackgroundRepeat type={4} className={recipesBox}>
                <ProductList className={recipeList} recipes={recipes} />
                <div className={secret}>
                    <h2 className={secretHeader}>{t('mulled.secret.header')}</h2>
                    <p className={secretContent}>{t('mulled.secret.description')}</p>
                </div>
            </BackgroundRepeat>
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }

        allStrapiOldRecipe(
            filter: {
                locale: { eq: $language }
                showIn: { eq: $site }
                isDummyContent: { eq: false }
            }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...oldRecipeFields
                }
            }
        }
    }
`;

export default MulledPage;
