// extracted by mini-css-extract-plugin
export var backgroundAnimation = "mulled-page-module--backgroundAnimation--ef83f";
export var backgroundTop = "mulled-page-module--background-top--3e727";
export var errorBlink = "mulled-page-module--error-blink--05c92";
export var introBox = "mulled-page-module--intro-box--d3642";
export var layout = "mulled-page-module--layout--2410e";
export var recipeList = "mulled-page-module--recipe-list--fe45a";
export var recipesBox = "mulled-page-module--recipes-box--5fb0d";
export var secret = "mulled-page-module--secret--9cd61";
export var secretContent = "mulled-page-module--secret-content--92f18";
export var secretHeader = "mulled-page-module--secret-header--aeb16";
export var topEdgeBox = "mulled-page-module--top-edge-box--a83fd";